import React from "react";
import "utils/sentry";
import * as Sentry from "@sentry/react";
import ErrorFallback from "components/ErrorFallback/ErrorFallback";
import { ApolloProvider } from "@apollo/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { client } from "helpers/apolloClientLink";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LanguageProvider from "components/LanguageProvider/LanguageProvider";
import useGetTranslationFiles from "components/LanguageProvider/utils/getTranslationFiles";
import store from "widgets/Donation/store/store";
import PaymentContainer from "./paymentContainer";

const DonationPage = () => {
  const { translations } = useGetTranslationFiles();
  if (!window.Chuffed.donationInit) {
    return;
  }

  // Basic information necessary to retrieve data
  const campaignId = window.Chuffed && window.Chuffed.donationInit.recipient.id;
  const perkId = (window.Chuffed && window.Chuffed.donationInit?.selectedPerk?.id) || null;
  const recipientType = window.Chuffed && window.Chuffed.donationInit.recipientType;
  const stripe = window.Chuffed && loadStripe(window.Chuffed.donationInit.stripePublishableKey);
  const captchaV3Key = window.Chuffed && window.Chuffed.donationInit.captcha.version_3;
  const captchaV2Key = window.Chuffed && window.Chuffed.donationInit.captcha.version_2;
  return (
    <Provider store={store}>
      <LanguageProvider translations={translations}>
        <ApolloProvider client={client}>
          <GoogleReCaptchaProvider reCaptchaKey={captchaV3Key}>
            <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
              <Elements stripe={stripe}>
                <PaymentContainer
                  campaignId={campaignId}
                  perkId={perkId}
                  recipientType={recipientType}
                  captchaV2Key={captchaV2Key}
                />
              </Elements>
            </Sentry.ErrorBoundary>
          </GoogleReCaptchaProvider>
        </ApolloProvider>
      </LanguageProvider>
    </Provider>
  );
};

export default DonationPage;
