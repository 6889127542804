import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptchaWrapper = ({ onChange, captchaKey }) => (
  <ReCAPTCHA
    style={{ display: "flex", justifyContent: "center" }}
    sitekey={captchaKey}
    onChange={onChange}
  />
);

export default ReCaptchaWrapper;
