import { CardNumberElement, ElementsConsumer, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";

/**
 * @components
 */
import CampaignCard from "components/CampaignCard";
import CurrencyModal from "components/CurrencyModal";
import ReCaptchaWrapper from "components/ReCaptchaWrapper";
import { renderFeeConfirmation } from "widgets/Donation/components/FeeConfirmation/renderFeeConfirmation";

/**
 * @helpers
 * */
import { usePrevious } from "helpers/previousHook";
import ScrollToError from "helpers/ScrollToError";

/**
 * @redux
 * */
import {
  createPaymentIntent as createPaymentIntentAction,
  initializeDonationForm as initializeDonationFormAction,
  setCardField as setCardFieldAction,
  setOptionalTier as setOptionalTierAction,
  submitDirectDebit as submitDirectDebitAction,
  submitDonation as submitDonationAction,
  submitPaymentElement as submitPaymentElementAction,
  updatePaymentIntent as updatePaymentIntentAction,
} from "widgets/Donation/store/state";

/**
 * @forms
 * */
import { getInitialValues } from "widgets/Donation/utils/getInitialValues";
import updatePrefillFields from "widgets/Donation/utils/updatePrefillFields";
import { handleValidation, validate } from "widgets/Donation/utils/validation";

/**
 * @utility
 * */
import { buildArrayOfDonationComponents } from "widgets/Donation/utils/buildArrayOfDonationComponents";
import { closeFeeConfirmation, updateFeeAmounts } from "widgets/Donation/utils/feeHelpers";
import { fundraiserChange } from "widgets/Donation/utils/fundraiserHelpers";
import { generateCampaignCard } from "widgets/Donation/utils/generateCampaignCard";

import {
  decodeRecipientOwner,
  donationFormEnabled,
  handleDonationAmountChanges,
  handlePaymentMethodChanges,
  hasStripe,
  isCustomTemplate,
  renderDonationFormError,
  shouldChangeCountryCode,
} from "widgets/Donation/utils/helpers";
// !change this
import { handlePaymentRequestSubmit } from "widgets/Donation/components/PaymentVendorInputs/createAutoPaymentRequest";

/**
 * @Stripe
 */
import { elements as cardElements } from "widgets/Donation/components/PaymentVendorInputs/Stripe/utils";
import {
  confirmPaymentIntent,
  handleDirectDebitPayment,
  handlePayPalSubmission,
  stripeRedirectToCheckout,
  submitPaymentElementPaymentIntent,
  submitPaymentIntent,
  validateToken,
} from "widgets/Donation/utils/paymentSubmissionHelpers";

import { hasPerk, isNotPerkOrImpactLevel } from "widgets/Donation/utils/perkHelpers";

/**
 * @language
 */
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import { Interweave } from "interweave";
import "./_style.scss";
import useGetPaymentMethodTypes from "./hooks/useGetPaymentMethodTypes";
import { getPaymentMethodTypes } from "./utils/getPaymentMethodOptions";

let updatedFees;

const setUpdatedFees = (feeInfo) => {
  updatedFees = feeInfo;
};

export const Donation = ({
  optionalTier,
  setOptionalTier,
  setCardField,
  donation,
  createPaymentIntent,
  isSubmitting,
  updatePaymentIntent,
  submitDonation,
  donationFormData,
  submitDirectDebit,
  directDebitSession,
  customContent,
  initializeDonationForm,
  requiresManualCaptcha,
  recaptchaErrorType,
  captchaV2Key,
  submitChangeCurrency,
  gqlLoadingErrors,
  loginUser,
  logoutUser,
  submitPaymentElement,
}) => {
  const stripe = useStripe();
  const [paymentElements, setPaymentElements] = useState();
  const [currencyModalStatus, setCurrencyModalStatus] = useState(false);
  const [attemptedPaymentIntentCreation, setAttemptedPaymentIntentCreation] = useState();
  const [initializedForm, setInitializedForm] = useState();
  const [showDonation, setShowDonation] = useState();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [feeConfirmation, setFeeConfirmation] = useState();
  const [processingSubmit, setProcessingSubmit] = useState();
  const [donationValues, setDonationValues] = useState();
  const [isPaymentRequestMethod, setIsPaymentRequestMethod] = useState();
  const [foreignCurrencyApplePay, setIsForeignCurrencyApplePay] = useState(); // eslint-disable-line no-unused-vars
  const [canMakePaymentType, setCanMakePaymentType] = useState();
  const [target, setTarget] = useState();
  const [hasBouncedToFirst, setHasBouncedToFirst] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [cardError, setCardError] = useState();
  const previousDonationFormData = usePrevious(donationFormData);
  const { translate: t } = useLanguageProvider();

  const paymentMethodTypes = useGetPaymentMethodTypes(donationFormData);

  const formRef = useRef();

  useEffect(() => {
    if (hasPerk) {
      setShowDonation(false);
    }

    if (isNotPerkOrImpactLevel(donationFormData) || !hasPerk) {
      setShowDonation(true);
    }

    setTarget(donationFormData?.target || donationFormData?.recipient);
  }, [donationFormData]);

  /* eslint react-hooks/exhaustive-deps: "off" */
  useEffect(() => {
    if (directDebitSession === "completed") {
      const directDebitCheckoutSession = donationFormData.directDebitCheckoutSession;
      stripeRedirectToCheckout({ stripe, directDebitCheckoutSession });
    }
  }, [directDebitSession]);

  const createIntentCall = useCallback(
    (captchaToken, captchaVersion, paymentMethodTypes) => {
      createPaymentIntent(
        donationFormData.paymentVendors.filter((vendor) =>
          vendor.includes("stripe"))[0],
        donationFormData.recipientId,
        donationFormData.feeStructure.currency,
        captchaToken,
        captchaVersion,
        paymentMethodTypes,
      );
    },
    [
      donationFormData.paymentVendors,
      donationFormData.recipientId,
      donationFormData.feeStructure,
      createPaymentIntent,
    ],
  );

  useEffect(() => {
    if (
      donation?.paymentIntentsId === null
      && hasStripe(donationFormData)
      && !attemptedPaymentIntentCreation
      && captchaToken
      && paymentMethodTypes
    ) {
      setAttemptedPaymentIntentCreation(true);
      createIntentCall(captchaToken, "v3", paymentMethodTypes);
    }

    if (
      donationFormData
      && !initializedForm
      && !hasStripe(donationFormData)
      && captchaToken
    ) {
      initializeDonationForm();
      setInitializedForm(true);
    }
  }, [
    donation,
    createPaymentIntent,
    setInitializedForm,
    captchaToken,
  ]);

  useEffect(() => {
    if (stripe && hasStripe(donationFormData)) {
      /**
       * checks if apple pay and google pay are available
       */
      const paymentRequest = stripe.paymentRequest({
        country: shouldChangeCountryCode(
          donationFormData.feeStructure.countryCode,
        ),
        currency: donationFormData.feeStructure.currency,
        total: {
          label: `${decodeRecipientOwner(donationFormData.recipientOwner)}`,
          amount: 0,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(paymentRequest);
          setCanMakePaymentType(result);
        }
      });
    }
  }, [stripe, donationValues]);

  useEffect(() => {
    if (paymentRequest && donationValues) {
      if (paymentRequest.hasRegisteredListener("paymentmethod")) {
        return;
      }

      paymentRequest.on("paymentmethod", async (ev) => {
        const isValidToken = await validateToken(
          ev.paymentMethod,
          donationFormData,
          donationValues,
          donationFormContext,
          handlers,
          updatedFees,
        );
        if (isValidToken.isValid || updatedFees?.isSubmittingFromConfirmation) {
          handlers.setIsPaymentRequestMethod(true);
          handlers.updatePaymentIntent({
            ...donationValues,
            ...updatedFees,
            ...donationFormData,
            ...donation,
            closeFeeConfirmation: closeFeeConfirmation(handlers),
            doSubmit: true,
            fromPaymentRequestButton: true,
            paymentIntentSecret: donation.paymentIntentsId,
            paymentIntentComplete: ev.complete,
            paymentMethod: ev.paymentMethod,
            stripeCardBrand: ev.paymentMethod.card.brand,
            stripeCardCountry: ev.paymentMethod.card.country,
            stripe,
            submitPaymentIntent: handlers.confirmPaymentIntent,
            cardErrorHandler,
          });
        } else {
          ev.complete("fail");
          updateFeeAmounts(
            true,
            isValidToken.updatedFeeConfirmation,
            formRef.current.setFieldValue,
          );
        }
      });
    }
  }, [
    paymentRequest,
    donationValues,
  ]);

  useEffect(() => {
    if (!previousDonationFormData) {
      return;
    }

    if (
      previousDonationFormData.feeStructure !== donationFormData.feeStructure
      && donationFormData.exchangeRate
    ) {
      const updatedDonationAmount = Number(
        formRef.current.values.donationAmount * donationFormData.exchangeRate,
      ).toFixed(0);
      handleDonationAmountChanges(
        updatedDonationAmount,
        donationFormData,
        formRef.current,
        optionalTier,
      );
    }

    if (previousDonationFormData.perks !== donationFormData.perks) {
      const updatedPerksCart = {
        ...formRef.current.values.perksCart,
        ...donationFormData.perks,
      };
      formRef.current.setFieldValue("perksCart", updatedPerksCart);
    }

    if (previousDonationFormData.prefill !== donationFormData.prefill) {
      updatePrefillFields(donationFormData.prefill, formRef.current);
    }
  }, [previousDonationFormData, donationFormData]);

  const handlePaymentElementSubmission = (
    formValues,
    stripe,
    elements,
    donationFormData,
    donationFormContext,
    handlers,
  ) => {
    const {
      setDonationValues,
      closeFeeConfirmation,
    } = handlers;

    setDonationValues(formValues);
    setIsPaymentRequestMethod(false);
    submitPaymentElement({
      ...formValues,
      ...donationFormData,
      ...donation,
      elements: paymentElements,
      closeFeeConfirmation: closeFeeConfirmation(handlers),
      doSubmit: true,
      fromPaymentRequestButton: false,
      paymentIntentSecret: donation.paymentIntentsId,
      stripe,
      submitPaymentElementPaymentIntent,
      cardErrorHandler,
      paymentMethodTypes: getPaymentMethodTypes(donationFormData),
    });
  };

  const handleStripeSubmission = async (
    formValues,
    stripe,
    elements,
    donationFormData,
    donationFormContext,
    handlers,
    setFieldValue,
  ) => {
    if (processingSubmit) {
      return;
    }

    const element = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(element);

    if (error) {
      setCardError(error.message);
      throw Error("Unable to make card payment.");
    }

    const {
      setDonationValues,
      closeFeeConfirmation,
      updateFeeAmounts,
      updatePaymentIntent,
    } = handlers;

    setDonationValues(formValues);
    setIsPaymentRequestMethod(false);

    if (token) {
      const isValidToken = await validateToken(
        token,
        donationFormData,
        formValues,
        donationFormContext,
        handlers,
        updatedFees,
      );
      if (isValidToken.isValid || updatedFees?.isSubmittingFromConfirmation) {
        updatePaymentIntent({
          ...formValues,
          ...donationFormData,
          ...donation,
          closeFeeConfirmation: closeFeeConfirmation(handlers),
          doSubmit: true,
          fromPaymentRequestButton: false,
          paymentIntentSecret: donation.paymentIntentsId,
          stripeCardBrand: token.card.brand,
          stripeCardCountry: token.card.country,
          stripe,
          cardElem: element,
          submitPaymentIntent: handlers.submitPaymentIntent,
          cardErrorHandler,
        });
      } else {
        updateFeeAmounts(
          true,
          isValidToken.updatedFeeConfirmation,
          setFieldValue,
        );
      }
    }

    if (error) {
      submitDonation({
        ...donationFormData,
        stripeToken: "error",
        errorMessage: error.message,
      });
    }
  };

  const handleSubmit = (
    values,
    stripe,
    elements,
    donationFormData,
    donationFormContext,
    handlers,
    setFieldValue,
    setFieldTouched,
  ) => {
    if (values.submitButton === "card") {
      if (
        !cardElements().cardNumberValid
        || !cardElements().cardDetailsValid
        || !cardElements().cvcValid
      ) {
        setCardField({
          error: "Please enter your card details",
          invalid: true,
        });
      }

      handleStripeSubmission(
        values,
        stripe,
        elements,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldValue,
      );
    }

    if (values.submitButton === "paymentElement") {
      handlePaymentElementSubmission(
        values,
        stripe,
        elements,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldValue,
      );
    }

    if (values.submitButton === "paymentRequest") {
      handlePaymentRequestSubmit(
        values,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldTouched,
      );
    }

    if (values.submitButton === "paypal") {
      handlePayPalSubmission(
        values,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldValue,
      );
    }
  };

  const handleCurrencyModalStatus = () => setCurrencyModalStatus(!currencyModalStatus);

  const captchaChange = (captchaValue) => {
    if (captchaValue) {
      createIntentCall(captchaValue, "v2", paymentMethodTypes);
    }
  };

  const cardErrorHandler = (error) => {
    setCardError(error);
    donationFormContext.donation.isSubmitting = false;
  };

  const handlers = {
    handlePaymentMethodChanges: (
      paymentMethod,
      campaignData,
      formProps,
      optionalTier,
    ) => handlePaymentMethodChanges(paymentMethod, campaignData, formProps, optionalTier),
    handleDonationAmountChanges: (
      donationAmount,
      campaignData,
      formProps,
      optionalTier,
    ) => handleDonationAmountChanges(donationAmount, campaignData, formProps, optionalTier),
    handleStripeSubmit: () => { },
    openCurrencyModal: () => handleCurrencyModalStatus,
    saveUpdatedCurrency: () => { },
    setOptionalTier: (tier) => setOptionalTier(tier),
    setCardField: (field) => setCardField(field),
    submitDonation: (formData) => submitDonation(formData),
    updatePaymentIntent: (formData) => updatePaymentIntent(formData),
    setProcessingSubmit: () => setProcessingSubmit,
    setFeeConfirmation: (data) => setFeeConfirmation(data),
    setDonationValues: (values) => setDonationValues(values),
    submitPaymentIntent: (
      data,
      redirectUrl,
      closeFeeConfirmation,
      paymentMethod,
      complete,
      stripe,
      cardElem,
      cardErrorHandler,
    ) => submitPaymentIntent(
      data,
      redirectUrl,
      closeFeeConfirmation,
      paymentMethod,
      complete,
      stripe,
      cardElem,
      cardErrorHandler,
    ),
    closeFeeConfirmation: (handlers) => closeFeeConfirmation(handlers),
    updateFeeAmounts: (
      isNew,
      feeConfirmation,
      setFieldValue,
    ) => updateFeeAmounts(isNew, feeConfirmation, setFieldValue),
    setIsPaymentRequestMethod: (paymentRequestMethod) => setIsPaymentRequestMethod(paymentRequestMethod),
    setIsForeignCurrencyApplePay: () => setIsForeignCurrencyApplePay,
    confirmPaymentIntent: (
      data,
      redirectUrl,
      closeFeeConfirmation,
      paymentMethod,
      complete,
      stripe,
    ) => confirmPaymentIntent(data, redirectUrl, closeFeeConfirmation, paymentMethod, complete, stripe),
    fundraiserChange: (
      e,
      donationFormData,
      handlers,
      formProps,
    ) => fundraiserChange(e, donationFormData, handlers, formProps),
    setTarget: (target) => setTarget(target),
    handleDirectDebitPayment: (
      event,
      formValues,
      handlers,
      donationFormContext,
    ) => handleDirectDebitPayment(event, formValues, handlers, donationFormContext),
    submitDirectDebitAction: (formData) => submitDirectDebit({ ...formData, ...donationFormData }),
    setShowDonation: () => setShowDonation,
    setUpdatedFees: (feeInfo) => setUpdatedFees(feeInfo),
    setPaymentElements: (elements) => setPaymentElements(elements),
    logoutUser: () => logoutUser(),
    loginUser: (user) => loginUser(user),
  };

  const handleCurrencyChange = (countryIsoCode) => {
    submitChangeCurrency({
      variables: {
        feeStructureCountry: countryIsoCode,
        campaignCurrency:
          donationFormData.feeStructure.currency
          || donationFormData.campaignCurrency,
      },
    });
  };

  const donationFormContext = useMemo(
    () => ({
      hasPerk,
      optionalTier,
      paymentRequest,
      canMakePaymentType,
      donation,
      processingSubmit,
      feeConfirmation,
      isSubmitting,
      isPaymentRequestMethod,
      showDonation,
      target,
      gqlLoadingErrors,
    }),
    [
      optionalTier,
      paymentRequest,
      canMakePaymentType,
      donation,
      processingSubmit,
      feeConfirmation,
      isSubmitting,
      isPaymentRequestMethod,
      showDonation,
      target,
      gqlLoadingErrors,
    ],
  );

  const validateFormik = (values) =>
    handleValidation({
      values,
      donationFormData,
      validate,
      handleSubmitFailed: ScrollToError,
      hasBouncedToFirst,
      setHasBouncedToFirst,
    });

  return (
    <>
      <GoogleReCaptcha
        onVerify={(token) => {
          if (!captchaToken) {
            setCaptchaToken(token);
          }
        }}
      />
      {!donation.isLoading ? (
        requiresManualCaptcha && recaptchaErrorType !== "critical" ? (
          <ReCaptchaWrapper
            onChange={captchaChange}
            captchaKey={captchaV2Key}
          />
        ) : (
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <div className="payment-wrapper">
                {donationFormEnabled(
                  donationFormData,
                  donation.paymentIntentsId,
                ) ? (
                  <div className="payment donation-form">
                    <Interweave content={customContent} className="payment__header" />
                    <div className="payment__body">
                      {!isCustomTemplate(donationFormData) && (
                        <div className="payment__campaign">
                          <div
                            className="payment__campaign-card --v2 --desktop"
                            data-test="donation-form-campaign-card"
                          >
                            <CampaignCard
                              campaign={generateCampaignCard(
                                target,
                                donationFormData,
                              )}
                            />
                          </div>
                        </div>
                      )}

                      <div className="payment__donate --v2">
                        <Formik
                          innerRef={formRef}
                          initialValues={getInitialValues(donationFormData)}
                          validate={validateFormik}
                          onSubmit={(
                            values,
                            { setFieldValue, setFieldTouched },
                          ) =>
                            handleSubmit(
                              values,
                              stripe,
                              elements,
                              donationFormData,
                              donationFormContext,
                              handlers,
                              setFieldValue,
                              setFieldTouched,
                            )}
                        >
                          {({ ...props }) => (
                            <Form data-test="donation-form">
                              {buildArrayOfDonationComponents(
                                donationFormData,
                                handlers,
                                props,
                                donationFormContext,
                              )}
                            </Form>
                          )}
                        </Formik>
                        {cardError && (
                          <p
                            className="donation-form__card-error well"
                            data-test="donation-form-card-error"
                          >
                            {cardError}
                            {" "}
                            Please try an alternative payment method.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  ) : (
                    renderDonationFormError(
                      donationFormData.paymentVendors,
                      donation.paymentIntentsId,
                    )
                  )}

                {feeConfirmation?.isVisible
                  && !feeConfirmation?.isSubmittingFromConfirmation
                  && renderFeeConfirmation(
                    donationFormData,
                    donationValues,
                    feeConfirmation,
                    donationFormContext,
                    handlers,
                    formRef.current.setFieldValue,
                  )}

                {currencyModalStatus && (
                  <CurrencyModal
                    currentCurrency={donationFormData.feeStructure.currency}
                    currencyModaOpen={currencyModalStatus}
                    exitCurrencyConversion={handleCurrencyModalStatus}
                    submitChangeCurrency={handleCurrencyChange}
                  />
                )}

              </div>
            )}
          </ElementsConsumer>
        )
      ) : (
        <div className="payment__loader">
          <div className="payment__loader-content">
            <span className="payment__loader-text">
              {t("Loading payment form")}
              ...
            </span>
            <div className="payment__loader-spinner">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  optionalTier: state.donation.optionalTier,
  donation: state.donation,
  directDebitSession: state.donation.directDebitSession,
  requiresManualCaptcha: state.donation.requiresManualCaptcha,
  recaptchaErrorType: state.donation.recaptchaErrorType,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentIntent: (campaignId) =>
    dispatch(updatePaymentIntentAction(campaignId)),
  createPaymentIntent: (
    vendor,
    campaignId,
    currency,
    captchaToken,
    captchaVersion,
    paymentMethodTypes,
  ) => dispatch(
    createPaymentIntentAction(vendor, campaignId, currency, captchaToken, captchaVersion, paymentMethodTypes),
  ),
  setOptionalTier: (tier) => dispatch(setOptionalTierAction(tier)),
  setCardField: (field) => dispatch(setCardFieldAction(field)),
  submitDonation: (formData) => dispatch(submitDonationAction(formData)),
  submitDirectDebit: (formData) => dispatch(submitDirectDebitAction(formData)),
  submitPaymentElement: (formData) => dispatch(submitPaymentElementAction(formData)),
  initializeDonationForm: () => dispatch(initializeDonationFormAction()),
});

const DonationConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Donation);

export default DonationConnected;
