import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { render as markdown } from "helpers/markdown";
import "./style.scss";
import ErrorHandler from "components/ErrorHandler";
import Donation from "widgets/Donation";

import getUrlParameter from "helpers/getUrlParameter";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import { hasPaymentPrefill } from "./helpers/hasPaymentPrefill";
import { mapDonationFormData } from "./helpers/mapDonationFormData";
import { remapFeeStructure } from "./helpers/remapFeeStructure";
import {
  calculateConvertedPrice,
  getCurrencyExchangeRate,
} from "./helpers/getCurrencyExchangeRate";
import getPrefillData from "./helpers/getPrefillData";

import GET_DONATION_FORM_DATA from "./queries/getDonationFormData.gql";
import GET_DONATION_FORM_DATA_WITH_PERK from "./queries/getDonationFormDataWithPerk.gql";
import GET_CURRENCY_CONVERSION_FEE_STRUCTURE from "./queries/getCurrencyConversion.gql";
import PAYMENT_PREFILL from "./queries/paymentPrefill.gql";
import GET_DIRECT_DEBIT from "./queries/getDirectDebit.gql";

const PaymentContainer = ({
  campaignId,
  perkId,
  recipientType,
  captchaV2Key,
}) => {
  const [customTemplateContent, setCustomTemplate] = useState(null);
  const [donationFormData, setDonationFormData] = useState();
  const [directDebit, setDirectDebit] = useState();
  const isPrefill = hasPaymentPrefill(window.location.search);
  const { translate: t } = useLanguageProvider();

  /* eslint react-hooks/rules-of-hooks: "off" */
  const { loading, error: loadingError, data } = perkId
    ? useQuery(GET_DONATION_FORM_DATA_WITH_PERK, {
      variables: {
        id: campaignId,
        perkId,
      },
    })
    : (
      isPrefill
        ? useQuery(PAYMENT_PREFILL, {
          variables: {
            id: campaignId,
            paymentPrefillInput: {
              token: isPrefill.token,
              email: isPrefill.email,
            },
          },
        })
        : useQuery(GET_DONATION_FORM_DATA, {
          variables: {
            id: campaignId,
          },
        })
    );

  const [getDirectDebit, {
    error: directDebitError,
  }] = useMutation(
    GET_DIRECT_DEBIT,
    {
      onCompleted(data) {
        setDirectDebit(data);
      },
    },
  );

  const [
    getCurrencyConversion,
    { error: loadingFeeStructureError, data: feeStructureData },
  ] = useLazyQuery(GET_CURRENCY_CONVERSION_FEE_STRUCTURE);

  const loginUser = (user) => {
    if (!user) {
      return;
    }

    if (user.isVerified) {
      const prefill = { prefill: user };
      const donationForm = { ...donationFormData, ...prefill };
      setDonationFormData(donationForm);
    }
  };

  const logoutUser = () => {
    // regular giving paymentPrefill do not remove prefilled data
    if (!isPrefill) {
      const prefill = {};
      const donationForm = { ...donationFormData, prefill };
      setDonationFormData(donationForm);
    }
  };

  /* eslint react-hooks/exhaustive-deps: "off" */
  useEffect(() => {
    if (!data || !directDebit) {
      return;
    }
    const feeStructure = {
      feeStructure: data?.campaign?.financialInformation?.feeStructure?.state,
    };
    const prefill = getPrefillData(data);
    const perks = { perks: data.perk };
    const repeatDonor = data.viewer;

    const donationForm = mapDonationFormData(
      {
        ...data.campaign,
        ...feeStructure,
        ...prefill,
        ...directDebit,
        ...perks,
        repeatDonor,
      },
      { campaignId, perkId, recipientType },
      getUrlParameter("f") || null,
    );
    setDonationFormData(donationForm);
  }, [data, directDebit]);

  useEffect(() => {
    if (data?.campaign?.donationForm?.template && /\/donate/.test(window.location.pathname)) {
      const customTemplate = data?.campaign.donationForm.template || null;
      const customContent = customTemplate
        ? markdown(customTemplate?.content)
        : null;
      setCustomTemplate(customContent);
    }
  }, [data]);

  useEffect(() => {
    if (/\/donate/.test(window.location.pathname)) {
      return;
    }
    if (data?.campaign.donationForm.template && donationFormData?.customTemplate) {
      donationFormData.customTemplate = null;
      setDonationFormData(donationFormData);
    }
  }, [data, donationFormData]);

  useEffect(() => {
    if (!feeStructureData) {
      return;
    }

    const feeStructure = {
      feeStructure: remapFeeStructure(
        feeStructureData.feeStructure.state,
        donationFormData.financialInformation,
      ),
    };
    const exchangeRate = getCurrencyExchangeRate(feeStructureData);

    if (donationFormData.perks) {
      const updatedPerkPrice = calculateConvertedPrice(
        donationFormData.perks.price,
        exchangeRate,
      );
      const updatedDonationFormData = {
        ...donationFormData,
        ...feeStructure,
        exchangeRate,
        perks: { ...donationFormData.perks, price: updatedPerkPrice },
        selectedPerk: {
          ...donationFormData.selectedPerk,
          price: updatedPerkPrice,
        },
      };
      setDonationFormData(updatedDonationFormData);
      return;
    }

    if (donationFormData.levels) {
      donationFormData.levels.forEach(
        (level) => { level.amount = calculateConvertedPrice(level.amount, exchangeRate); },
      );
      const updatedDonationFormData = {
        ...donationFormData,
        ...feeStructure,
        exchangeRate,
        levels: donationFormData.levels,
      };
      setDonationFormData(updatedDonationFormData);
      return;
    }

    setDonationFormData({ ...donationFormData, ...feeStructure, exchangeRate });
  }, [feeStructureData]);

  useEffect(() => {
    getDirectDebit({
      variables: {
        createDirectDebitId: campaignId,
      },
    });
  }, [campaignId]);

  if (loadingError) {
    return (
      <ErrorHandler
        networkError={loadingError?.networkError}
        graphQLErrors={loadingError.graphQLErrors}
      />
    );
  }

  if (loading || !donationFormData) {
    return (
      <div className="payment__loader">
        <div className="payment__loader-content">
          <span className="payment__loader-text">
            {t("Loading payment form")}
            ...
          </span>
          <div className="payment__loader-spinner">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Donation
      donationFormData={donationFormData}
      customContent={customTemplateContent}
      submitChangeCurrency={getCurrencyConversion}
      captchaV2Key={captchaV2Key}
      loginUser={loginUser}
      logoutUser={logoutUser}
      gqlLoadingErrors={{
        directDebitError,
        loadingFeeStructureError,
      }}
    />
  );
};

export default PaymentContainer;
